import { createApp } from 'vue';
import App from './App.vue';
import './assets/styles.css';

// Import Vuetify
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Import Vuetify's CSS
import '@mdi/font/css/materialdesignicons.css'; // Import Material Design Icons

// Import Vuetify components
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// Create Vuetify instance
const vuetify = createVuetify({
    components, // Register all components
    directives, // Register all directives
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        defaultTheme: 'light',
    },
});

// Create Vue app
const app = createApp(App);
app.use(vuetify); // Use Vuetify plugin
app.mount('#app');
