<!-- PrivatePromptsMain.vue -->
<template>

  <!-- Banner mit Hintergrundfarbe -->
  <v-container fluid class="banner">
    <v-row>
      <v-col cols="12" class="text-center">
        <h3>
          Deine Daten gehören dir!
        </h3>
        <h1 class="font-weight-bold">
          Private Prompts
        </h1>
        <h4>
          KI und Webdienste sicher benutzen mit Private Prompts
        </h4>
      </v-col>
    </v-row>
  </v-container>

  <!-- Video-Bereich -->
  <v-container class="video-section" fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card outlined hover class="video-card">
          <!-- Schräger Banner oben rechts -->
          <div class="video-banner">Dieses Video<br />wurde mit Hilfe<br />von KI erstellt</div>
          <v-img src="./mvp-teaser-image.png" aspect-ratio="16/9"
            alt="Vorschaubild des Einführungsvideos zu Private Prompts">
            <!-- Play-Button in der Mitte -->
            <v-container class="fill-height" fluid justify="center">
              <v-btn @click="playVideo">Video abspielen</v-btn>
            </v-container>
          </v-img>
          <v-card-text class="text-center">
            In diesem kurzen Video erfährst du, wie <span class="brand-name">Private Prompts</span> deine Daten schützt
            und dir mehr Kontrolle über deine Privatsphäre gibt.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container fluid class="status-message">
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <h2>Gleich Ausprobieren</h2>
      </v-col>
      <v-col cols="12" md="10" class="text-center">
        <p>Schütze deine sensiblen Informationen und nutze KI sicher und privat. Mit <span class="brand-name">Private
            Prompts</span> behältst du die Kontrolle über deine Daten.
          <span class="brand-name">Private Prompts</span> ist gerade in der Entwicklungsphase und noch nicht fertig.
          Aber du
          kannst als einer der
          Ersten die Testversion für Windows und Linux schon einmal ausprobieren!
        </p>
        <p><v-btn color="secondary"
            href="https://github.com/fboerncke/private-prompts-prototype/releases/tag/v0.001-MVP-001" target="_blank">
            <v-icon size="32">mdi-cloud-download-outline</v-icon>&nbsp;Downloadseite mit Release Notes</v-btn>
        </p>
        <p>Es wird in den kommenden Monaten regelmäßig neue Versionen geben. Du kannst die Updates
          ausprobieren und mit deinem Testfeedback und Wünschen dabei helfen, dass <span class="brand-name">Private
            Prompts</span>
          zu einer noch besseren Anwendung wird.</p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Schnellstart Download -->
  <v-container fluid class="quickstart">
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <h2>Schnellstart</h2>
      </v-col>
      <v-col cols="12" md="10" class="text-center">
        <p>
          Du weisst, was du möchtest und magst nicht viel lesen? Dann kannst du eine Testversion von <span
            class="brand-name">Private
            Prompts</span> direkt hier herunterladen und dann ohne Installation starten:
        </p>
        <v-row>
          <v-col cols="3" class="d-flex align-center justify-end">
            <strong>Windows</strong>
          </v-col>
          <v-col cols="9" class="d-flex align-center justify-start">
            <v-btn color="secondary"
              href="https://github.com/fboerncke/private-prompts-prototype/releases/download/v0.001-MVP-001/PrivatePrompts-MVP-001.exe"
              target="_blank">
              <v-icon size="32">mdi-microsoft-windows</v-icon>&nbsp;PrivatePrompts-MVP-001.exe</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="d-flex align-center justify-end">
            <strong>Linux</strong>
          </v-col>
          <v-col cols="9" class="d-flex align-center justify-start">
            <v-btn color="secondary"
              href="https://github.com/fboerncke/private-prompts-prototype/releases/download/v0.001-MVP-001/PrivatePrompts-MVP-001.AppImage"
              target="_blank">
              <v-icon size="32">mdi-penguin</v-icon>&nbsp;PrivatePrompts-MVP-001.AppImage</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="d-flex align-center justify-end">
            <strong>MacOS</strong>
          </v-col>
          <v-col cols="9" class="d-flex align-center justify-start">
            <v-btn color="secondary" href="" target="">
              <v-icon size="32">mdi-apple</v-icon>&nbsp;noch nicht verfügbar</v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>

  <!-- Schritt-für-Schritt-Anleitung -->
  <v-container class="how-it-works" fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>So funktioniert es</h2>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-cloud-download</v-icon>
        <h3>1. Download</h3>
        <p>
          Lade <span class="brand-name">Private Prompts</span> kostenlos herunter. Für Windows und Linux gibt es
          ausführbare Dateien, die einfach per Doppelklick gestartet werden können.
          Das funktioniert auf Wunsch auch vom USB-Stick aus.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-power</v-icon>
        <h3>2. Einfach Starten</h3>
        <p>
          Starte die Anwendung durch Doppelklick. Eine Installation ist nicht erforderlich. Lediglich eine
          Konfigurationsdatei wird auf deinem Rechner gespeichert, sodass du Eingaben nicht erneut vornehmen musst.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-fingerprint</v-icon>
        <h3>3. Mehr Sicherheit</h3>
        <p>
          Nutze KI sicher, privat und risikofrei. Du definierst nach deinen Vorstellungen, was für dich privat ist
          und welche Informationen
          besser
          auf deinem Rechner bleiben sollen, wenn du Dienste aus dem Internet benutzt.
        </p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Vorteile auf einen Blick -->
  <v-container class="benefits" fluid>
    <v-row>
      <v-col cols="12" class="text-center">
        <h2>Deine Vorteile auf einen Blick</h2>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>
        <h3>Keine Cloud</h3>
        <p>
          Behalte die Kontrolle über deine sensiblen Daten. Mit <span class="brand-name">Private Prompts</span> bleiben
          alle Informationen
          sicher auf deinem eigenen Rechner. Keine unerwünschte Weitergabe, kein Risiko.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>
        <h3>Intuitive Bedienung</h3>
        <p>
          Starte sofort, ohne technische Hürden. <span class="brand-name">Private Prompts</span> ist so gestaltet, dass
          du es ohne Vorkenntnisse nutzen
          kannst. Einfach herunterladen, starten und loslegen.
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <v-icon size="48" color="secondary">mdi-check-bold</v-icon>

        <h3>Du entscheidest</h3>
        <p>
          Bestimme selbst, welche Informationen privat bleiben sollen. Mit <span class="brand-name">Private
            Prompts</span> kannst du festlegen, welche Daten auf deinem Rechner bleiben und welche verarbeitet werden
          dürfen.
        </p>
      </v-col>
    </v-row>
  </v-container>

  <!-- Anwendungsbeispiele -->
  <v-container class="use-cases" fluid>
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <v-icon size="64" color="secondary">mdi-sitemap</v-icon>

        <h2>Anwendungsbeispiele</h2>
      </v-col>
      <!-- Beispiel 1 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <v-card-title class="wrap">Schreibe sichere Briefe</v-card-title>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Erstelle Dokumente, ohne dir Sorgen um die Sicherheit deiner Daten oder der dir anvertrauten Informationen
            zu machen. <span class="brand-name">Private Prompts</span> hilft dir dabei, vertrauliche
            E-Mails, Angebote oder Berichte
            zu verfassen, während sensible Informationen geschützt bleiben.
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Beispiel 2 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <v-card-title class="wrap">Schutz für Kundendaten</v-card-title>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Verbessere deinen Kundenservice, ohne dabei Kompromisse beim Datenschutz machen zu müssen. <span
              class="brand-name">Private
              Prompts</span> hilft dir, Kundenanfragen effizient zu bearbeiten und dabei trotzdem die persönlichen
            Daten deiner
            Kunden zu
            schützen.
          </v-card-text>
        </v-card>
      </v-col>
      <!-- Beispiel 3 -->
      <v-col cols="12" md="4" class="d-flex">
        <v-card outlined hover class="flex-grow-1 d-flex flex-column">
          <v-card-item>
            <v-card-title class="wrap">Texte sicher übersetzen</v-card-title>
          </v-card-item>
          <v-card-text class="flex-grow-1">
            Möchtest du wichtige Dokumente oder persönliche Nachrichten übersetzen, ohne sensible Inhalte offenzulegen?
            Mit <span class="brand-name">Private Prompts</span> kannst du Übersetzungsdienste wie <i>Google
              Translate</i> oder <i>deepl.com</i> mit mehr Sicherheit benutzen.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- Feedback und Mitwirkung -->
  <v-container class="feedback" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <v-icon size="64" color="secondary">mdi-account-group</v-icon>
        <h2>Mach mit bei Private Prompts</h2>
        <p>
          Deine Meinung zu <span class="brand-name">Private Prompts</span> interessiert mich:
          Teile deine Ideen und Wünsche mit mir, um <span class="brand-name">Private Prompts</span> zu einer Lösung zu
          machen, die viele Menschen nützlich finden. Gemeinsam machen wir die Anwendung besser.
        </p>

        <v-btn color="secondary" href="mailto:frank.boerncke@gmail.com" target="_blank">
          <v-icon left>mdi-message-text-outline</v-icon>&nbsp;Feedback</v-btn>


      </v-col>
    </v-row>
  </v-container>

  <!-- Über mich -->
  <v-container class="about-me" fluid>
    <v-row justify="center">
      <v-col cols="12" md="10" class="text-center">
        <v-icon size="64" color="secondary">mdi-account</v-icon>
        <h2>Über Frank Börncke</h2>
        <p>
          Hallo, ich bin Frank Börncke, freiberuflicher Softwareentwickler und Erfinder von <span
            class="brand-name">Private Prompts</span>. Dieses
          Projekt ist aus meinem Wunsch heraus entstanden, die Kontrolle über die eigenen Privatsphäre bei der Nutzung
          von KI und Webdiensten wieder mehr in
          die Hände der Nutzer zu legen. Ich möchte eine Lösung erstellen, die Vertrauen schafft und dir die Sicherheit
          gibt, dass deine Daten bei dir bleiben. Unterstütze mich auf dieser Reise, um eine sichere und verlässliche
          KI-Lösung für alle zugänglich zu machen.
        </p>

        <p>
          Die Entwicklung von <span class="brand-name">Private Prompts</span> wird gefördert durch das Bundesministerium
          für Bildung und
          Forschung und den Prototype Fund.
        </p>
        <!-- Kontaktmöglichkeiten -->
        <div class="social-links mt-4">
          <v-btn icon color="secondary" href="mailto:frank.boerncke@gmail.com">
            <v-icon size="32">mdi-email</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://www.linkedin.com/in/frankboerncke/" target="_blank">
            <v-icon size="32">mdi-linkedin</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://x.com/frank_boerncke" target="_blank">
            <v-icon size="32">mdi-twitter</v-icon>
          </v-btn>
          <v-btn icon color="secondary" href="https://github.com/fboerncke/private-prompts-prototype" target="_blank">
            <v-icon size="32">mdi-github</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <!-- Footer mit rechtlichen Informationen -->
  <v-footer class="footer-banner">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="text-center">
          <p>&copy; 2024 Frank Börncke, Private Prompts</p>
          <v-btn color="secondary" text href="https://www.applicate.de/impressum/index.html">Impressum</v-btn>
          &nbsp;
          <v-btn color="secondary" text href="https://www.applicate.de/datenschutz/index.html">Datenschutz</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>

  <!-- Dialog für Video -->
  <v-dialog v-model="videoDialog" max-width="800px">
    <v-card>
      <v-card-text>
        <iframe width="100%" height="450" src="https://app.heygen.com/embeds/f38b706847334f108f42a91b172be357"
          title="Einführungsvideo zu Private Prompts" frameborder="0" allow="encrypted-media; fullscreen;"
          allowfullscreen></iframe>
        <strong>Hinweis</strong>: Die Person in dem Video ist nicht der Autor sondern ein KI-generierter Avatar.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PrivatePromptsMain',
  data() {
    return {
      videoDialog: false,
      hoveredIcon: null,
    };
  },
  methods: {
    playVideo() {
      this.videoDialog = true;
    }
  },
};
</script>

<style scoped>
/* Schriftarten */
body {
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
}

/* Responsive Schriftgrößen */
h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
}

h2 {
  font-size: clamp(2rem, 4vw, 3rem);
}

h3 {
  font-size: clamp(1.75rem, 3vw, 2.5rem);
}

p,
li {
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 1.5em;
  /* Erhöhter Abstand zwischen Absätzen */
}

/* Begrenzung der Textbreite */
.text-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Markierung von "Private Prompts" */
.brand-name {
  font-weight: bold;
  color: var(--accent-color);
}


/* Hintergrundbild mit Overlay */
.v-img {
  position: relative;
  transition: transform 0.3s ease;
}

.v-img::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(42, 157, 143, 0.7),
      rgba(38, 70, 83, 0.7));
}

.v-img>.v-container {
  position: relative;
  z-index: 1;
}

.v-img:hover {
  transform: scale(1.02);
}

/* Buttons */
.v-btn {
  border-radius: 25px;
  transition: all 0.3s ease;
  background-color: var(--primary-color);
  text-transform: none;
  /* This prevents text from being converted to uppercase */
}

.v-btn:hover {
  background-color: var(--secondary-color) !important;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* Icons */
.v-icon {
  color: var(--primary-color);
}

.v-icon:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

/* Textfarben */
.body {
  background-color: var(--background-color);
  color: var(--text-color);
}

body,
p,
li {
  color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--secondary-color);
  font-family: 'Montserrat', sans-serif;
}

/* Abschnitte */
.video-section,
.introduction,
.status-message,
.quickstart,
.how-it-works,
.benefits,
.use-cases,
.feedback,
.about-me {
  padding: 50px 15px;
}

.video-section {
  background-color: #F1F1F1;
}

.status-message {
  background-color: #F5F5DC;
}

.quickstart {
  background-color: #EAEAEA;
}

.how-it-works {
  background-color: #F5F5DC;
}

.benefits {
  background-color: #EAEAEA;
}

.use-cases {
  background-color: #F5F5DC;
}

.feedback {
  background-color: #EAEAEA;
}

.about-me {
  background-color: #F5F5DC;
}

/* Karten und Bilder */
.v-card {
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.v-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.v-card-title {
  font-weight: bold;
  color: var(--secondary-color);
}

.v-card-title.wrap {
  white-space: normal;
}

.v-card-text {
  color: var(--text-color);
  flex-grow: 1;
}


.v-col.d-flex {
  display: flex;
}

.v-img:hover {
  transform: scale(1.05);
}

/* Social Links */
.social-links .v-btn {
  margin: 0 10px;
}

.social-links .v-icon {
  color: var(--text-color);
}

/* Footer */
.v-footer {
  background-color: var(--secondary-color) !important;
}

.v-footer .white--text {
  color: #ffffff !important;
}

/* Video-Bereich Anpassungen */
.video-card {
  position: relative;
}

.video-banner {
  position: absolute;
  top: 30px;
  right: -100px;
  background-color: rgba(255, 111, 97, 0.9);
  /* Akzentfarbe mit Transparenz */
  color: white;
  padding-left: 100px;
  padding-right: 100px;
  transform: rotate(45deg);
  font-weight: bold;
  z-index: 2;
}

.video-card .v-img {
  position: relative;
}

.video-card .v-img .v-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-card .v-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-card .v-icon {
  font-size: 64px;
}


/* Banner-Stil */
.banner {
  background: linear-gradient(60deg, #3aad9f, #466673);
  padding: 60px 15px;
}


.banner h1 {
  font-size: 3rem;
  color: #092925;
}

.banner h2 {
  font-size: 1.5rem;
  color: #264653;
  margin-bottom: 10px;
}

.banner h3 {
  font-size: 1.4rem;
  color: #264653;
  margin-bottom: 0px;
}

.banner h4 {
  font-size: 1.25rem;
  color: #264653;
  margin-bottom: 10px;
}

.footer-banner {
  background: linear-gradient(60deg, #3aad9f, #466673);
}

@media (max-width: 600px) {
  .banner {
    padding: 40px 15px;
  }
}

@media (min-width: 600px) {

  .how-it-works .v-col,
  .benefits .v-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
