<template>
  <v-app>
    <v-main>

      <!--DEMO /-->
      <PrivatePromptsMain msg="Welcome to Private Prompts" />
      <!--PrivatePromptsMain2 msg="Welcome to Private Prompts" /-->
    </v-main>
  </v-app>
</template>

<script>
import PrivatePromptsMain from './components/PrivatePromptsMain.vue'
//import PrivatePromptsMain2 from './components/PrivatePromptsMain2.vue'
//import DEMO from './components/DEMO.vue'

export default {
  name: 'App',
  components: {
    PrivatePromptsMain,
    //  PrivatePromptsMain2,
    //DEMO
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
